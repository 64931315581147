import React from "react"
import { graphql } from "gatsby"
// import Link from "@components/Link"
// import { Button } from "@material-ui/core"
import Layout from "@components/layout"
import SEO from "@components/seo"
// import { rhythm } from "@utils/typography"
import GridContainer from '@components/Grid/GridContainer'
import GridItem from '@components/Grid/GridItem'
import clsx from 'clsx'
import styles from '@assets/jss/views/components.js'
import { makeStyles } from '@material-ui/core/styles'
import Parallax from '@components/Parallax/Parallax'
import TimeTable from "@components/TimeTable/TimeTable"

const useStyles = makeStyles(styles)

const Curricula  = (props) => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  const classes = useStyles()
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Curricula" />
      <Parallax
        filter
        image={require("@assets/img/landing-bg.jpg")}
        style={{ height: "180px" }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}></div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={clsx(classes.main, classes.mainRaised)}  style={{ marginTop: "-80px" }}>
        <div className={classes.container}>
          <h2 className={classes.subtitle}>课程设置</h2>
          <br />
          <TimeTable />
          <br /><br /><br />
        </div>
      </div>
    </Layout>
  )
}


export default Curricula

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
// allGoogleSheetTimeTableRow {
    //   nodes {
    //     weekday
    //     className
    //     teacher
    //     notes
    //     timeStart
    //     timeEnd
    //     color
    //   }
    // }
    