import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash'

const useStyles = makeStyles({
  table: {
    minWidth: "500px"
  }
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#efefef',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#ffffff',
    },
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  root: {
    borderRight: "1px solid #dedede",
    height: "22px",
    '&:nth-of-type(1)': {
      backgroundColor: 'inherit',
      padding: '0 12px',
      position: 'sticky',
      // top: 'auto',
      // width: '80px',
    }
  },
  head: {
    '&:nth-of-type(1)': {
      backgroundColor: '#3b490c',
      padding: '0 12px'
    },
    backgroundColor: '#3b490c', // theme.palette.warning.dark,
    color: theme.palette.common.white,
    fontWeight: "bold"
  },
  body: {
    fontSize: 14,
    padding: 0
  },
}))(TableCell);

const TimeTable = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      allGoogleSpreadsheetDanceSheetTimeTable {
        nodes {
          weekday
          className
          teacher
          notes
          timeStart
          timeEnd
          color
        }
      }
    }
  `)
  const timeRange = [
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30'
  ]
  const rowsTemplate = timeRange.map((timePoint) => {
    return {
      timePoint,
      Sunday: '',
      Monday: '',
      Tuesday: '',
      Wednesday: '',
      Thusday: '',
      Friday: '',
      Saturday: ''
    }
  })
  const timeDifferent = (timeString1, timeString2) => {
    const time1 = new Date('2000-01-01 ' + timeString1)
    const time2 = new Date('2000-01-01 ' + timeString2)
    return Math.abs(time1 - time2)
  }
  const data2rows = (dataArray, rowsTemplate) => {
    dataArray.forEach(((item) => {
      const rowStart = _.findLastIndex(rowsTemplate, ((row) => {
        return item.timeStart >= row.timePoint
      }))
      const rowEnd = _.findIndex(rowsTemplate, ((row) => item.timeEnd <= row.timePoint))
      const timeDifferentItem = timeDifferent(item.timeEnd, item.timeStart)
      const timeDifferentPoint = timeDifferent(rowsTemplate[rowEnd].timePoint, rowsTemplate[rowStart].timePoint)
      const height = Math.floor((timeDifferentItem / timeDifferentPoint) * 22 * (rowEnd - rowStart + 1)) + 'px'
      const timeDifferentTop = timeDifferent(item.timeStart, rowsTemplate[rowStart].timePoint)
      const timeDifferentCell = timeDifferent('00:30', '00:00')
      const marginTop = Math.floor((timeDifferentTop / timeDifferentCell) * 22) + 'px'
      rowsTemplate[rowStart][item.weekday] = {
        ...item,
        rowspan: rowEnd - rowStart + 1,
        height,
        marginTop
      }
      _.range(rowStart + 1, rowEnd + 1).forEach((index => {
          rowsTemplate[index][item.weekday] = 'x'
      }))
    }))
    return rowsTemplate
  }
  const rows = data2rows(data.allGoogleSpreadsheetDanceSheetTimeTable.nodes, rowsTemplate)
  // console.log(rows)
  const createColumn = (column, index) => {
    return ((typeof column === 'object') && 
      <StyledTableCell key={index} align="center" style={{verticalAlign: 'top'}} rowSpan={column.rowspan}>
        <Card variant="outlined" style={{backgroundColor: column.color, height: column.height, marginTop: column.marginTop, overflow: 'visible'}}>
          <CardContent style={{padding: '3px', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Typography component="h4">
              {column.className}
            </Typography>
            <Typography color="textSecondary">
              {column.teacher}
            </Typography>
            <Typography variant="body2" component="p">
              {column.timeStart} {column.timeEnd}
            </Typography>
          </CardContent>
        </Card>
      </StyledTableCell>) ||
      ((column !== 'x' && column !=='') &&  <StyledTableCell key={index} align="center">{column}</StyledTableCell>) ||
      ((column === '') && <StyledTableCell key={index} align="center"></StyledTableCell>)
  }

  return <TableContainer component={Paper}>
        <Table className={classes.table} stickyHeader aria-label="time table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">&nbsp;</StyledTableCell>
              <StyledTableCell align="center">Sunday<br />星期天</StyledTableCell>
              <StyledTableCell align="center">Monday<br />星期一</StyledTableCell>
              <StyledTableCell align="center">Tuesday<br />星期二</StyledTableCell>
              <StyledTableCell align="center">Wendesday<br />星期三</StyledTableCell>
              <StyledTableCell align="center">Thusday<br />星期四</StyledTableCell>
              <StyledTableCell align="center">Friday<br />星期五</StyledTableCell>
              <StyledTableCell align="center">Saturday<br />星期六</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.timePoint}>
                { createColumn(row.timePoint, 0) }
                { createColumn(row.Sunday, 1) }
                { createColumn(row.Monday, 2) }
                { createColumn(row.Tuesday, 3) }
                { createColumn(row.Wednesday, 4) }
                { createColumn(row.Thusday, 5) }
                { createColumn(row.Friday, 6) }
                { createColumn(row.Saturday, 7) }
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
}

export default TimeTable
